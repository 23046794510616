import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../common/constants/lsnetx.constants';

/**
 * display screen for order cancelled
 * @class OrderCancelledComponent 
 */
@Component({
  selector: 'app-order-cancelled',
  templateUrl: './order-cancelled.component.html',
  styleUrls: ['./order-cancelled.component.scss']
})
export class OrderCancelledComponent implements OnInit {

  storeName = Constants.WAR_NAME;

  constructor(
  ) { }

  ngOnInit() {

  }

}
