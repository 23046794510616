import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { SuggestionOptionComponent } from './suggestion-option.component';
import { SearchTagComponent } from './suggestion-tag.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { Router } from '@angular/router';
import { SuggestionComponent } from '../../../LSNG/components/ls-suggestion-field/suggestion/suggestion.component';

/**
 * contains search and suggestion-field,
 * on suggestion-option selection or keyword search, it navigates to appropriate route.
 * @class SearchComponent
 */
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  /**
   * url for fetching suggestions for keyword
   */
  baseUrl: string;
  /**
   * queryParameters to suggestion-url
   */
  parameters: string;
  /**
   * close search component event
   */
  @Output('closeSearchEvent') closeSearchEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
  ) { }

  /**
   * initialize base-url and queryParameters for request to fetch suggestions for keyword.
   * @method ngOnInit
   */
  ngOnInit() {
    this.baseUrl = Constants.BASE_URL + "/getProductSuggestions";
    this.parameters = Constants.SITE_ID + "&storeId=" + Constants.STORE_ID + "&isSuggestion=" + true + "&numRecords=10&isCategorySearch=" + false + "&companyId=" + Constants.VENDOR_DETAILS.companyId;
  }

  optionComponent = SuggestionOptionComponent;
  tag = SearchTagComponent;

  @ViewChild(SuggestionComponent,{static: false}) suggestionField

  /**
   * when search-icon is clicked, then search-request fetches data for the keyword present in the search-field,
   * and navigates to search page, where keyword is set in the query-params.
   * @method changeUrlSearch
   * @param suggestionEleVal : contains value present in the search-textfield.
   */
  changeUrlSearch(suggestionEleVal, suggestionEle) {
    let data = suggestionEleVal;
    this.suggestionField.textValue = "";
    if (data != undefined && data != "") {
      this.router.navigate(['search'], { queryParams: { k: data } })
    }
  }

  /**
   * If, enter key is pressed in search-component, then page navigates to search page with search-keyword in url's query-parameters.
   * else, when option is selected, page redirects to navigation-url (detail-page of product) of option. 
   * @method navigateToDetailPage
   * @param event : event contains redirection-url in case of option-selection or search-keyowrd in case of enter keypress.
   * @param suggestionEle : deprecated 
   */
  navigateToDetailPage(event, suggestionEle) {
    let data = event.value;
    if (event.special) {
      this.suggestionField.textValue = "";
      if (data != undefined && data != "") {
        this.router.navigate(['search'], { queryParams: { k: data } })
      }
    } else {
      this.suggestionField.textValue = "";
      if (data != undefined && data != "") {
        location.href = 'details/' + data
      }
    }
  }

  clicked(event) {
    event.stopPropagation();
  }

  /**
   * when close btn is clicked, this closeSearchEvent is  triggered.
   * @method closeSearch
   */
  closeSearch() {
    this.closeSearchEvent.emit();
  }

}
