<div fxLayout="column" class="popUpMsgInfoDiv" *ngIf="showOkPopup">
  <div class="body">
    <p>{{msg}}</p>
  </div>
  <div class="footer">
    <button class="okBtn" (click)="closeDialog()">OK</button>
  </div>
</div>
<div *ngIf="!showOkPopup && showCheckoutAgreement" class="checkoutAgreementPnl">
  <div class="checkoutInnerPnl">
      <div class="upperPnl">
          <div class="heading">
            <p>CheckOut Agreement</p>
          </div>
          <span class="fa fa-times-circle-o" (click)="closeDialog()"></span>
        </div>
        <div class="lowerPnl">
          <div [innerHtml]="checkoutAgreement"></div>
        </div>
  </div>
</div>