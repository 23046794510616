<div fxLayout="column" class="login-component">
  <div id="login-inner">
    <div class="login heading-lbl">
      <p class="heading">Login to&nbsp;{{storeName}}</p>
      <span class="close-pop-up fa fa-close" (click)="onClick()"></span>
    </div>
    <div class="leftPnl col-xs-6">
      <!-- <div class="tab">
        <p class="login-tab active" (click)="changeTab($event,loginTab,signUpForm,login,signUp, errorLabel)" #login>Login</p>
        <p class="signup-tab" (click)="changeTab($event,loginTab,signUpForm,login,signUp,errorLabel)" #signUp>Signup</p>
      </div> -->
      <div class="hdr">
        <p id="mainHeading">Sign In</p>
        <p id="subHeading">For Returning Customer</p>
      </div>
      <p class="error-lbl" #errorLabel></p>
      <div class="login-form" #loginTab>
        <form role="form" [formGroup]="loginForm" (ngSubmit)="loginSubmit($event, errorLabel)" class="login-desk-view">
          <div class="login-email">
            <p id="label">Email Adderss*&nbsp;:</p>
            <input formControlName="email" type="email" tabindex="1" id="txtEmailLogin" class="txtFieldCls txtEmailLogin"
              placeholder="Enter email address">
          </div>
          <!-- <input fxShow.lt-sm fxHide.gt-xs formControlName="email" type="email" tabindex="1" id="txtEmailLogin" class="txtFieldCls txtEmailLogin" -->
          <!-- style="margin:0;" placeholder="Your Email"> -->
          <div class="login-password">
            <p id="label">Password&nbsp;:</p>
            <input formControlName="password" type="password" tabindex="2" id="txtPasswordLogin" class="txtFieldCls txtPasswordLogin"
              placeholder="Password">
          </div>
          <div>
            <div class="chckbx">
              <input formControlName="remember" class="chckbx-selector" type="checkbox" tabindex="3" id="rememberMeChk">
              <label class="chckbx-label" for="rememberMeChk">Remember Me</label>
            </div>
            <a (click)="goToForgotPw()" tabindex="5" id="lnkForgotPwd" class="lnkForgotPwd">Forgot password?</a>
          </div>
          <!-- <input fxShow.lt-sm fxHide.gt-xs formControlName="password" type="password" tabindex="2" id="txtPasswordLogin" class="txtFieldCls txtPasswordLogin" -->
          <!-- placeholder="Password"> -->
          <div class="button-div">
            <button id="lnkLoginBtn" class="button lnkLoginBtn" tabindex="4">Sign In</button>
          </div>
        </form>
        <!-- <form fxShow.lt-sm fxHide.gt-xs role="form" [formGroup]="loginMobileForm" (ngSubmit)="loginSubmit($event, errorLabel)" class="login-mobile-view">
          <input fxShow.lt-sm fxHide.gt-xs formControlName="email" type="email" tabindex="1" id="txtEmailLogin" class="txtFieldCls txtEmailLogin"
            style="margin:0;" placeholder="Your Email">
          <input fxShow.lt-sm fxHide.gt-xs formControlName="password" type="password" tabindex="2" id="txtPasswordLogin" class="txtFieldCls txtPasswordLogin"
            placeholder="Password">
          <div>
            <div class="chckbx">
              <input formControlName="remember" class="chckbx-selector" type="checkbox" tabindex="3" id="rememberMeChk">
              <label class="chckbx-label" for="rememberMeChk">Remember Me</label>
            </div>
            <a (click)="goToForgotPw()" tabindex="5" id="lnkForgotPwd" class="lnkForgotPwd">Forgot password?</a>
          </div>
          <button id="lnkLoginBtn" class="button lnkLoginBtn" tabindex="4" >Login</button>
        </form> -->
      </div>
    </div>
    <div class="rightPnl loginVia col-xs-6">
      <div class="hdr">
        <p id="mainHeading">Sign Up</p>
        <p id="subHeading">For New Customer</p>
      </div>
      <div class="signup-form" #signUpForm>
        <form id="frmSignup" [formGroup]="registerForm" (ngSubmit)="signUpSubmit($event, errorLabel)" class="sign-up-view">

          <div class="signup-name">
            <p id="label">Name*&nbsp;:</p>
            <input type="text" formControlName="name" placeholder="Name" tabindex="1" id="txtNameSignup" class="txtFieldCls txtNameSignup">
          </div>
          <!-- <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName = "name" tabindex = "1" id = "txtNameSignup" class = "txtFieldCls txtNameSignup" -->
          <!-- placeholder = "Name*"> -->

          <div class="signup-email">
            <p id="label">Email Adderss*&nbsp;:</p>
            <input type="email" formControlName="emailSignUp" placeholder="Email" tabindex="1" id="txtEmailSignup"
              class="txtFieldCls txtEmailSignup">
          </div>
          <!-- <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName="name" tabindex="1" id="txtNameSignup" class="txtFieldCls txtNameSignup" -->
          <!-- placeholder="Email Address*"> -->
          <div class="signup-password">
            <p id="label">Password&nbsp;:</p>
            <input type="password" tabindex="3" formControlName="passwordSignUp" placeholder="Password" id="txtPasswordSignup"
              class="txtFieldCls txtPasswordSignup" (keyup)="manageStrength(pwdStrength)">
            <div id="pnlStrength" class="pnlStrength">
              <span id="spPwdStrength" #pwdStrength></span>
            </div>
          </div>
          <!-- <input fxShow.lt-sm fxHide.gt-xs type="password" tabindex="3" formControlName="passwordSignUp" id="txtPasswordSignup" class="txtFieldCls txtPasswordSignup" -->
          <!-- placeholder="Password"> -->
          <div class="button-div">
            <button id="lnkSignupBtn" class="button lnkSignupBtn" tabindex="4"> Sign Up</button>
          </div>
        </form>
        <!-- <form fxShow.lt-sm fxHide.gt-xs id="frmSignup" [formGroup]="mobileRegisterForm" (ngSubmit)="signUpSubmit($event, errorLabel)" class="sign-up-mob-view">
      
              <div id="signUpPnl" class="signUpPnl">
                <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName="name" tabindex="1" id="txtNameSignup" class="txtFieldCls txtNameSignup"
                  placeholder="Name*">
                <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName="emailSignUp" tabindex="1" id="txtNameSignup" class="txtFieldCls txtNameSignup"
                  placeholder="Email Address*">
                <input fxShow.lt-sm fxHide.gt-xs type="password" tabindex="3" formControlName="passwordSignUp" id="txtPasswordSignup" class="txtFieldCls txtPasswordSignup"
                  placeholder="Password">
                <button id="lnkSignupBtn" class="button lnkSignupBtn" tabindex="4"> Signup</button>
              </div>
            </form> -->
      </div>
    </div>
  </div>

  <div class="social-signin-container hide">
    <div *ngIf="fbOauth" id="fbPnl" class="fbPnl" tabindex="10" (click)="socialSignIn('facebook', errorLabel)">
      <img src="assets/images/facebook_login.png">
      <a id="fbLnk" class="lnkFB">Connect with Facebook </a>
    </div>
    <div id="gPlusPnl" *ngIf="googleOauth" class="gPlusPnl" tabindex="11" (click)="socialSignIn('google', errorLabel)">
      <img src="assets/images/google_login.png">
      <a id="gPlusLnk" class="gPlusLnk">Connect with Google</a>
    </div>
  </div>
  <img src="assets/images/or.png" class="or-img hide">

</div>