import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { GetReviewListService } from '../../../services/get-review-list.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { UserModel } from '../../../common/models/user-model';
import { ReviewAvgModel } from '../../../common/models/reviewAvgModel';
import { ReviewModel } from '../../../common/models/reviewModel';

/**
 * product-review form for submitting review of a product.
 * @class ProductReviewComponent
 */
@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.scss']
})
export class ProductReviewComponent implements OnInit {

  /**
   * @var dialog: dialog instance of the component
   */
  dialog: LsMdDialog<ProductReviewComponent>;
  lblAddReview = "Add Reviews/Rating";
  note = "*Either Review or Rating is Mandatory";
  lblTitle = "Title*:";
  lblReview = "Review*:";
  lblRating = "Rating*:";
  lblButton = "Add Review";
  /**
   * @var data: data sent back to parent-component, from which this component is opened as pop-up.
   */
  data;
  addReviewForm: FormGroup;
  pageNum = 1;
  numRecords = 28;
  ratingAndReviewData: ReviewAvgModel;
  titleId;
  variantId;
  title;
  review;
  rating;
  userId;
  reviewId;
  currUser: UserModel;
  edit = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public formBuilder: FormBuilder,
    public getReviewListService: GetReviewListService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) { }

  /**
   * intializes review-form,
   * fetches current-user's name and userId from local-storage,
   * fetches titleId and variantId from current-url
   * @method ngOnInit
   */
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.addReviewForm = this.formBuilder.group({
        title: ["", [Validators.required]],
        review: [""],
        rating: [""]
      })
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        this.userId = this.currUser.userId;
      }
      this.titleId = this.activatedRoute.snapshot.queryParamMap.get("t").toString();
      if (this.activatedRoute.snapshot.queryParamMap.has("sizeRecNum")) {
        this.variantId = this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum").toString();
      } else {
        this.variantId = "";
      }
      /**
       * If, user has already submitted the review for the product, then review-form will be opened in edit-form. So, check for the user and product that review has been already submitted or not. 
       */
      this.checkForEdit();
    }
  }

  /**
   * closes current dialog.
   * @method closeDialog
   */
  closeDialog() {
    this.dialog.close(this.data);
  }

  /**
   * check if user has already submitted the review for the product or not.
   * If yes, then populate the form with previous review-data (i.e; edit-mode of form)
   * @method checkForEdit 
   */
  checkForEdit() {
    /**
     * fetch all reviews for that product,
     * then check if, there exit any review with same userID as current user's userId.
     * If, yes then populate review-form with review-data, else do nothing.
     */
    this.getReviewListService.getReviewList(this.activatedRoute.snapshot.queryParamMap.get("t").toString(), this.pageNum, this.numRecords, (data) => {
      if (data != undefined) {
        this.ratingAndReviewData = data.data;
        if (this.ratingAndReviewData && this.ratingAndReviewData.reviewModels) {
          this.ratingAndReviewData.reviewModels.forEach(model => {
            if (model.userId == this.userId) {
              this.edit = true;
              this.setData(model);
            }
          });
        }
      }
    });
  }

  /**
   * sets review-data in review-form when in edit-mode.
   * @method setData
   * @param model : ReviewModel - contains review related data
   */
  setData(model: ReviewModel) {
    this.addReviewForm.setValue({
      title: model.reviewTitle,
      review: model.reviewText,
      rating: model.productRating
    });
    this.reviewId = model.reviewId;
  }

  /**
   * Submits review form,
   * If succesfully submitted, then reload the current page, so that user can see updated reviews,
   * else, show appropriate error-message.
   * @method addReviewSubmit
   * @param $event : deprecated
   */
  addReviewSubmit($event) {
    let reviewModel: ReviewModel = new ReviewModel();
    if (this.addReviewForm.value.title != "" && (this.addReviewForm.value.review != "" || this.addReviewForm.value.rating != "")) {
      reviewModel.reviewTitle = this.addReviewForm.value.title;
      reviewModel.reviewText = this.addReviewForm.value.review;
      reviewModel.productRating = this.addReviewForm.value.rating;
      reviewModel.userId = this.userId;
      reviewModel.titleId = this.titleId;
      reviewModel.productVariantId = this.variantId;
      reviewModel.reviewId = this.reviewId;
      this.getReviewListService.addReview(reviewModel, (resp) => {
        if (!resp.error) {
          if (resp.data != null) {
            this.dialog.close(null);
            this.okayMessageDialogService.open(OkayMessageComponent, {}, Constants.POPUP_MSGS.REVIEW_SUCCESSFUL_UPDATED).subscribe(response => {
              if (isPlatformBrowser(this.platformId)) {
                window.location.reload();
              }
            });
          }
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, Constants.POPUP_MSGS.REVIEW_UPDATION_UNSUCCESSFUL).subscribe(response => {
          });
          this.dialog.close(null);
        }
      });
    }
  }

}
