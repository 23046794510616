import { Component, OnInit } from '@angular/core';

/**
 * display screen for successful order completion.
 * @class OrderConfirmedComponent
 */
@Component({
  selector: 'app-order-confirmed',
  templateUrl: './order-confirmed.component.html',
  styleUrls: ['./order-confirmed.component.scss']
})
export class OrderConfirmedComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() { }

}
