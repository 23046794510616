import { Component, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CartService } from '../../../services/cart.service';
import { CartCountService } from '../../../services/cart-count.service';
import { CartModel } from '../../../common/models/cartModel';
import { YesNoPopupComponent } from '../../commonComponent/yes-no-popup/yes-no-popup.component';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { Constants } from '../../../common/constants/lsnetx.constants'
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { UserModel } from '../../../common/models/user-model';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { CouponDTO } from '../../../common/models/couponDto';
import { TemplateConstants } from '../../commonComponent/template-constants';

/**
 * cart component- for showing items in cart. It can be a dialog or a route, based upon template.
 * cart contains - item's image, name, mrp, discount, quantity, total amount, total tax etc.
 * It also has proceed button to go to checkout page.
 * @class CartComponent
 */

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [CartService]
})
export class CartComponent implements OnDestroy {

  cart: CartModel = new CartModel();
  cartProducts: Array<CartProductModel> = new Array();
  currType: string = "INR";
  totalSavings: number = 0;
  grandTotal: number = 0;
  taxes: number = 0;
  shippingCharges: number = 0;
  handlingCharges: number = 0;
  cartNum: number = 0;
  total_Amount: number = 0;
  /**
   * @var currUser: contains current logged-in user's information (it can be guest user also).
   */
  currUser;
  emptyCart: boolean = false;
  isWishlist: boolean = false;
  dialog: LsMdDialog<CartComponent>;
  enquireVisibility: boolean = false;
  priceRoundOffInteger: string = '1.2';
  userState: UserModel;
  /**
   * @var loggedIn: if user is logged-in or not
   */
  loggedIn: boolean = false;
  _userSubscription;
  _cartCountSubscription;
  couponApplied: string;
  couponAmount: number = 0;
  shippingDays: number = Constants.DEFAULT_SHIPPING_DAYS;
  defaultPath = TemplateConstants.defaultImgPath;
  cartImgPath = TemplateConstants.templateAssetsPath + '/images/whiteCart.png';
  goShoppingPath = TemplateConstants.templateAssetsPath + '/images/Go-SHopping.png';
  /**
   * @var cartCount: contains number of items present in cart
   */
  cartCount: number = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cartService: CartService,
    public YesNoDialogService: LsDialogService<YesNoPopupComponent>,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    private router: Router,
    private cartCountService: CartCountService,
    private userService: UserService
  ) {

  }


  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      /**
       * If user is logged-in, then get current user's information.
       */
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
      }
      /**
       * check if user is logged in or not
       */
      this._userSubscription = this.userService.userStateModel.subscribe((userModel) => {
        this.userState = userModel;
        if (Object.keys(userModel).length != 0) {
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      });
      /**
       * get current-cart
       */
      this.getUserCart();
      /**
       * get count for products present in cart
       */
      this.getCartCount();
      /**
       * handle enquiry button visibility
       */
      if (Constants.ONLINE_STORE_SETTING_MODEL.integrationDTO.crmServiceUrl != undefined && Constants.ONLINE_STORE_SETTING_MODEL.integrationDTO.crmServiceUrl != '') {
        this.enquireVisibility = true;
      }
      /**
       * handle currency
       */
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
      /**
       * handle price-round-off for cart
       */
      if (Constants.ONLINE_STORE_SETTING_MODEL != undefined) {
        if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined) {
          if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1) {
            this.priceRoundOffInteger = '1.0';
          } else if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0) {
            this.priceRoundOffInteger = '1.2';
          }
        }
      }
    }
  }

  ngOnDestroy() {
    /**
     * unsubscribe all subscriptions from the component, when it destroys.
     */
    if (this._userSubscription) {
      this._userSubscription.unsubscribe();
    }
  }

  /**
   * @method removeFromLocalCart : removes local-cart and also updates cart-observable.
   */
   removeLocalCart() {     if(!isPlatformBrowser(this.platformId)) {       return     }
    localStorage.removeItem('localCart');
    this.cartCountService.updateCart(new CartModel());
  }

  /**
   * @method getAdditionalShippingDays : calculates additional shipping days
   * @param shippingDays : shipping days of a product
   * @returns addtionalShippingDays
   */
  getAdditionalShippingDays(shippingDays: number): number {
    return shippingDays + Constants.PRODUCT_SHIPPING_DAYS_ADDITION;
  }

  /**
   * @method getUserCart : get current-cart
   * If, user is logged-in, fetch cart by hitting request to '/cart/get',
   * else fetch local-cart.
   * Then populate cart items in cart. Handle empty cart.
   */
  getUserCart() {   if(!isPlatformBrowser(this.platformId)) {       return     }
    let validateOffers: boolean = true;
    let forceFetch: boolean;
    let cart = new CartModel();
    cart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
    if (this.loggedIn) {
      forceFetch = true;
    } else {
      /**
       * If not logged-in, fetch cart from local-storage , if present.
       */
      forceFetch = false;
      cart.cartProductModels = [];
      let localCart: CartModel = JSON.parse(localStorage.getItem('localCart'));
      if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
        localCart.cartProductModels.forEach(element => {
          let cartProductModel: CartProductModel = new CartProductModel();
          cartProductModel.titleId = element.titleId;
          cartProductModel.variantId = element.variantId;
          cartProductModel.numCopies = element.numCopies;
          cart.cartProductModels.push(cartProductModel);
        });
      }
    }
    this.cartService.getCart(cart, validateOffers, forceFetch, (data) => {
      if (!data.error) {
        if (data.data == null) {
          this.emptyCart = true;
          this.removeLocalCart();
          return;
        } else {
          /**
           * if cart is not empty, then populate cart.
           */
          this.populateCart(data.data[0], false, null);
          /**
           * If user is logged-in and logged-in user is not guest-user, Then fetch valid coupons for the cart.
           */
          if (this.currUser) {
            if (this.currUser.userType == Constants.UserType.guest) {
            } else {
              this.populateCoupons();
            }
          }
        }
      } else {
        this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error fetching cart!');
      }
    });
  }

  /**
   * @method getCartCount : gets count of products present in cart
   */
  getCartCount() {     if(!isPlatformBrowser(this.platformId)) {       return     }
    this._cartCountSubscription = this.cartCountService.cart.subscribe((cart) => {
      let currCart = cart;
      if (currCart && JSON.stringify(currCart) != '{}') {
        if (currCart.cartProductModels && currCart.cartProductModels.length > 0) {
          /**
           * check for offer product, ignore offer product from cart-count
           */
          let count = 0;
          currCart.cartProductModels.forEach((ele) => {
            if (!ele.offer) {
              count++;
            }
          });
          this.cartCount = count;
        } else {
          this.cartCount = 0;
        }
      } else {
        if (JSON.parse(localStorage.getItem('localCart'))) {
          let localCart = JSON.parse(localStorage.getItem('localCart'));
          if (localCart.cartProductModels && localCart.cartProductModels.length > 0) {
            /**
             * check for offer product, ignore offer product from cart-count
             */
            let count = 0;
            localCart.cartProductModels.forEach((ele) => {
              if (!ele.offer) {
                count++;
              }
            });
            this.cartCount = count;
          } else {
            this.cartCount = 0;
          }
        } else {
          this.cartCount = 0;
        }
      }
    });
  }

  /**
   * @method populateCart : populates cart-items also checks for empty-cart.
   * @param cart : current cart
   * @param isUpdate : deprecated
   * @param cartProduct deprecated
   */
  populateCart(cart: CartModel, isUpdate: boolean, cartProduct: CartProductModel) {
    if (cart) {
      this.cart = cart;
      this.cartProducts = [];
      if (this.cart.cartProductModels && this.cart.cartProductModels.length > 0) {
        this.cart.cartProductModels.forEach((ele) => {
          if (!ele.offer) {
            this.cartProducts.push(ele);
          }
        });
        this.cart.cartProductModels = this.cartProducts;
      } else {
        this.emptyCart = true;
        this.removeLocalCart();
      }
    } else {
      this.emptyCart = true;
      this.removeLocalCart();
    }
    /**
     * update variables holding various amounts for current-cart
     */
    if (!this.loggedIn) {
      this.calculatePricesForLocalCart();
    } else {
      this.calculatePrices();
    }
  }

  /**
   * @method onClose : closes cart-dialog.
   */
  onClose() {
    this.dialog.close(null);
  }

  /**
   * @method setVariantName : returns Variant Name.
   */
  setVariantName(obj) {
    let a: string = obj[Object.keys(obj)[0]];
    return a;
  }

  /**
   * @method increaseProQuantity : Increases quantity of product, if maximum quantity of that product in a cart is not reached.
   * @param cartProduct : cartProductModel of which quantity to be increased
   * @param idx : index of that cartProductModel in cart
   */
  increaseProQuantity(cartProduct, idx: number) {
    if (cartProduct.numCopies < cartProduct.productModel.maxOrderQty) {
      this.cart.cartProductModels[idx].numCopies++;
      this.updateCart(cartProduct);
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Maximum quantity reached');
    }
  }

  /**
   * @method decreaseProQuantity : Decreases quantity of product, if minimum quantity of that product in a cart is not reached.
   * If, quantity of a product was unit, then remove that product from cart.
   * @param cartProduct : cartProductModel of which quantity to be increased 
   * @param idx : index of that cartProductModel in cart
   */
  decreaseProQuantity(cartProduct, idx: number) {
    if (cartProduct.numCopies == 1) {
      this.removeProduct(cartProduct, idx);
      return;
    }
    if (cartProduct.numCopies > cartProduct.productModel.minOrderQty && cartProduct.numCopies > 1) {
      this.cart.cartProductModels[idx].numCopies--;
      this.updateCart(cartProduct);
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Minimum quantity reached');
    }
  }

  /**
   * @method updateCart : for updating cart
   * If user logged-in, then update cart by sending compute-cart request with updated cart, and update cart in local-storage as well.
   * else update local-cart.
   * @param cartProduct
   */
  updateCart(cartProduct: CartProductModel) {
    if (this.loggedIn) {
      this.cartService.computeCart(this.cart, (data) => {
        if (!data.error) {
          if (data.data && data.data[0]) {
            this.cartProducts = [];
            this.cart = data.data[0];
            if (this.cart.cartProductModels && this.cart.cartProductModels.length > 0) {
              this.cart.cartProductModels.forEach((ele) => {
                if (!ele.offer) {
                  this.cartProducts.push(ele);
                }
              });
              this.updateLocalCart(cartProduct);
              this.calculatePrices();
              this.cart.cartProductModels = this.cartProducts;
            } else {
              this.emptyCart = true;
              this.removeLocalCart();
            }
          } else {
            this.emptyCart = true;
            this.removeLocalCart();
          }
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error in cart updation!');
        }
      });
    } else {
      /**
       * calcaulate prices for local cart
       */
      this.calculatePricesForLocalCart();
      /**
       * update local-cart
       */
      this.updateLocalCart(cartProduct);
    }
  }

  /**
   * @method updateLocalCart : For maintaining cart in local-storage.
   * In cart, list of cartProductModels is maintained. CartProductModel contains, titleId, vaiantId, numCopies of a product.
   * If, the product id already present in the cart, then, only increase quantity of that product in the cart.
   * After updation in local-cart, update updated local-cart in local-storage.
   * @param cartProduct : cartProductModel of product to be added in cart
   */
  updateLocalCart(cartProduct: CartProductModel) { if(!isPlatformBrowser(this.platformId)) {       return     }
    var localCart = JSON.parse(localStorage.getItem('localCart'));
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      localCart.cartProductModels.forEach(element => {
        if (element.titleId == cartProduct.titleId) {
          if (element.variantId && cartProduct.productModel.id) {
            if (element.variantId == cartProduct.productModel.id) {
              element.numCopies = cartProduct.numCopies;
            }
          } else {
            element.numCopies = cartProduct.numCopies;
          }
        }
      });
      localStorage.setItem("localCart", JSON.stringify(localCart));
    }
  }

  /**
   * Helper method for removing product from cart.
   * Removes product from local-cart, and then again update local-cart in local-storage.
   * @param cartProduct : cartProductModel to be removed 
   */
  removeFromLocalCart(cartProduct: CartProductModel) { if(!isPlatformBrowser(this.platformId)) {       return     }
    var localCart: CartModel = JSON.parse(localStorage.getItem('localCart'));
    localCart.cartProductModels.forEach(element => {
      /**
       * first, check for titleId, if that product is present in cart or not,
       */
      if (element.titleId == cartProduct.titleId) {
        /**
         * if titleId matches and variantId id available for that product, then check for vaiantId.
         */
        if (element.variantId && cartProduct.productModel.id) {
          if (element.variantId == cartProduct.productModel.id) {
            localCart.cartProductModels.splice(localCart.cartProductModels.indexOf(element), 1);
          }
        } else {
          localCart.cartProductModels.splice(localCart.cartProductModels.indexOf(element), 1);
        }
      }
    });
    /**
     * update updated local-cart
     */
    localStorage.setItem("localCart", JSON.stringify(localCart));
  }

  /**
   * Removes product from cart.
   * Confirmation dialog comes up, if 'yes', then check is user logged-in or not.
   * If not logged-in, then remove product from local-cart and update cart-product's list, if cart gets empty, then show empty cart.
   * If logged-in, then call computeCart request with updated cart (after removing product from the list). 
   * After response, populate cart, if cart gets empty, then show empty cart.
   * @param cartProduct : cartProductModel to be removed 
   * @param idx : index of that cartProductModel in cart
   */
  removeProduct(cartProduct: CartProductModel, idx: number) {
    this.YesNoDialogService.open(YesNoPopupComponent, {}, "Are you sure you want to remove this product from your bag?").subscribe(response => {
      if (response == 'yes') {
        if (!this.loggedIn) {
          this.removeFromLocalCart(cartProduct);
          this.cart.cartProductModels.splice(idx, 1);
          this.populateCart(this.cart, true, cartProduct);
          /**
           * empty cart handling
           */
          if (this.cart.cartProductModels.length == 0) {
            this.emptyCart = true;
            this.removeLocalCart();
          }
        } else {
          let currCart = this.cart;
          currCart.cartProductModels.splice(idx, 1);
          this.cartService.computeCart(currCart, (resp) => {
            if (!resp.error) {
              if (resp.data && resp.data[0]) {
                this.removeFromLocalCart(cartProduct);
                this.populateCart(resp.data[0], true, cartProduct);
              } else {
                /**
                * empty cart handling
                */
                this.emptyCart = true;
                this.removeLocalCart();
              }
            } else {
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error removing product from cart!');
            }
          });
        }
      }
    });
  }

  /**
   * @method proceedToCheckout : redirects to checkout-page
   * Checks for maximum cart-amount and maximum quantity of products in the cart.
   */
  proceedToCheckout() {
    let error: boolean = false;
    if (Constants.CART_SETTINGS.maxCartAmount != undefined) {
      if (this.grandTotal > Constants.CART_SETTINGS.maxCartAmount) {
        error = true;
        this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Maximum Cart Amount is :' + Constants.CART_SETTINGS.maxCartAmount)
      } 
    } 
    if (!error && Constants.CART_SETTINGS.maxQtyOfAnItemInCart != undefined) {
      if (this.cartNum > Constants.CART_SETTINGS.maxQtyOfAnItemInCart) {
        error = true;
        this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Maximum Cart Item Count is :' + Constants.CART_SETTINGS.maxQtyOfAnItemInCart)
      } 
    } 
    if(!error){
      this.dialog.close(null);
      this.router.navigate(['checkout']);      
    }
  }

  /**
   * @method calculateSubTotal : calculates sub-total of a product.
   * @param product : cartProductModel of the product of which sub-total needs to be calculated.
   * @returns subTotal : of the product in th cart
   */
  calculateSubTotal(product: CartProductModel): number {
    let subTotal: number = 0;
    subTotal = (product.productModel.salePrice + product.productModel.gst + product.productModel.shippingCharges) * product.numCopies +
      product.productModel.handlingCharges;
    return subTotal;
  }

  /**
   * @method calculatePricesForLocalCart : update variables holding various amounts for the local-cart, whenever cart gets updated.
   * For local-cart, calculation for various amounts is done here only.
   */
  calculatePricesForLocalCart() {
    let taxes = 0, gst = 0, shippingCharges = 0, handlingCharges = 0, totalSavings = 0, offerAmount = 0, totalAmount = 0;
    if (this.cart && this.cart.cartProductModels && this.cart.cartProductModels.length > 0) {
      this.cart.cartProductModels.forEach((ele: CartProductModel) => {
        if (ele.productModel) {
          taxes += ele.productModel.gst * ele.numCopies;
          gst += ele.productModel.gst * ele.numCopies;
          shippingCharges += ele.productModel.shippingCharges * ele.numCopies;
          handlingCharges += ele.productModel.handlingCharges;
          totalSavings += ele.productModel.discount * ele.numCopies;
          offerAmount += ele.offerAmount;
          totalAmount += (ele.productModel.salePrice + ele.productModel.gst + ele.productModel.shippingCharges)
            * ele.numCopies + ele.productModel.handlingCharges;
        }
      });
    }
    this.cart.totalAmount = totalAmount;
    this.cart.totalSavings = totalSavings;
    this.cart.taxes = taxes;
    this.cart.shippingCharges = shippingCharges;
    this.cart.handlingCharges = handlingCharges;
    this.cart.offerAmount = offerAmount;
    this.calculatePrices();
  }

  /**
   * @method calculatePrices : update variables holding various amounts for the cart, whenever cart gets updated.
   */
  calculatePrices() {
    this.grandTotal = this.cart.totalAmount;
    this.totalSavings = this.cart.totalSavings;
    this.taxes = this.cart.taxes;
    this.shippingCharges = this.cart.shippingCharges;
    this.handlingCharges = this.cart.handlingCharges;
    this.cartNum = this.cart.cartNum;
    this.couponAmount = this.cart.offerAmount;
    /**
     * update current-cart in the cartObservable.
     */
    this.cartCountService.updateCart(this.cart);
  }

  /**
   * @var couponList : stores list of valid coupons for the cart. 
   */
  couponList: Array<CouponDTO> = [];

  /**
   * @method populateCoupons : get all valid-coupons for the cart and populate them in the cart.
   */
  populateCoupons() {
    let fetchEnquiries: boolean = false;
    this.cartService.getValidCoupons(fetchEnquiries, (resp) => {
      if (!resp.error) {
        if (resp.data) {
          let coupons: Array<CouponDTO> = [];
          coupons = resp.data;
          if (coupons.length > 0) {
            coupons.forEach(element => {
              if (element.offerId) {
                this.couponList.push(element);
              }
            });
          }
          /**
           * set coupon that is already applied to cart
           */
          if (this.couponList.length > 1) {
            this.setDefaultCoupon();
          }
        }
      }
    });
  }

  /**
   * @method setDefaultCoupon : set coupon that is already applied to the cart
   */
  setDefaultCoupon() {
    if (this.cart.offerIds && this.cart.offerIds.length > 0) {
      this.cart.offerIds.forEach((offer) => {
        this.couponList.forEach((coupon) => {
          if (offer == coupon.offerId) {
            this.couponApplied = coupon.couponCode;
          } else {
            this.couponApplied = undefined;
          }
        });
      });
    }
  }

  /**
   * @method openContactUs : for opening Contact-us page
   * First, close current cart-dialog, then navigate to Contact-us page.
   */
  openContactUs() {
    this.onClose();
    this.router.navigate(['/contact-us']);
  }

  /**
   * @method imgErrorHandler : error handling for image
   * @param event 
   */
  imgErrorHandler(event) {
    /**
     * once, onerror is received, then assign null to 'onerror' event for that target.
     */
    event.target.onerror = null;
    /**
     * update default-image in place of that image.
     */
    event.target.src = this.defaultPath;
  }

  /**
   * @method goToProductDetailPage : returns product-url for product-detail page
   * @returns target : product's detail page url
   */
  goToProductDetailPage(product) {
    let url: string;
    if (product.productURLText) {
      url = product.productURLText != undefined ? product.productURLText : '';
    }
    let target = '/details/' + url;
    return target;
  }

  /**
   * @method getQueryParamsForProduct : returns queryParams for a product-url (?t='titleID')
   * @returns queryParams : queryParams for a product-url 
   */
  getQueryParamsForProduct(product) {
    let queryParams;
    if (product.titleId) {
      queryParams = { 't': product.titleId };
    }
    return queryParams;
  }

}