import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OnlineStoreModel } from '../../../common/models/online-store-model';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-okay-message',
  templateUrl: './okay-message.component.html',
  styleUrls: ['./okay-message.component.scss']
})
export class OkayMessageComponent implements OnInit {

  msg;
  dialog: LsMdDialog<OkayMessageComponent>
  dataList;
  showCheckoutAgreement: boolean;
  showOkPopup: boolean = true;
  checkoutAgreement: string;
  companyDetails: OnlineStoreModel;

  constructor(
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.companyDetails = Constants.VENDOR_DETAILS;
    var cdnPath = this.companyDetails.checkoutAgreementUrl;
    if (cdnPath != undefined && cdnPath != "") {
      this.httpService.getCDNContent(cdnPath, (data) => {
        if (data != undefined && data != "")
          this.checkoutAgreement = data;
      });
    }
    this.msg = this.dataList;
    if(this.msg == 'checkout-agreement'){
     this.showCheckoutAgreement = true;
     this.showOkPopup = false;
    }
  }

  closeDialog() {
    this.dialog.close(null);
  }

}
