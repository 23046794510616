import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { TrackOrderService } from '../../../services/track-order.service';

/**
 * track-order component - pop-up where a user can fill information for tracking order.
 * @class TrackOrderComponent
 */

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.scss']
})
export class TrackOrderComponent implements OnInit {
  /**
   * @var dialog : instance of dialog
   */
  dialog: LsMdDialog<TrackOrderComponent>;
  /**
   * @var trackOrderForm : form-group for track-order form
   */
  trackOrderForm: FormGroup

  constructor(
    public formBuilder: FormBuilder,
    private trackOrderService: TrackOrderService,
    private router: Router
  ) { }

  ngOnInit() {
    this.trackOrderForm = this.formBuilder.group({
      email: ["", [Validators.email, Validators.required]],
      orderId: ["", [Validators.required]]
    })
  }

  /**
   * closes dialog
   * @method close
   */
  close() {
    this.dialog.close(null);
  }

  /**
   * methos checks if track-order form is invalid, if yes, then shows error message,
   * else, redirects to track-order page.
   * @method trackOrder
   * @param errorLabel 
   */
  trackOrder(errorLabel) {
    if (this.trackOrderForm.value.email === "") {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please enter your Email-Id";
    } else if (this.trackOrderForm.value.orderId === "") {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please enter your Order Id";
    } else {
      /**
       * track-order url contains orderID and emailId in url's query-params
       */
      let url = "track-order?orderId=" + this.trackOrderForm.value.orderId + "&email=" + this.trackOrderForm.value.email;
      location.href = url;
    }
  }

}
