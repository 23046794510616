import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';

import { HttpService } from '../../../services/http.service';
import { HomePageService } from '../../../services/home-page.service';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { StaticPageModel } from '../../../common/models/static-page-model';
import { Location } from '@angular/common';
import { TemplateConstants } from '../../commonComponent/template-constants';

/**
 * component shows all static-page content, based upon static-page-url (current-url) it gets.
 * @class StaticPageComponent
 */
@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent implements OnInit {
  quickLinks: Array<StaticPageModel>
  cdnPath: string
  errorFlag: boolean = true;
  heading: string
  content: string
  img404Src: string
  defaultPath: string = TemplateConstants.templateAssetsPath + '/images/errorPages/404_pageNotFound.jpg';
  routerNavFlag: boolean;
  _routeSubscription;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private homePageService: HomePageService,
    public location: Location,

  ) { }

  /**
   * find current page url,
   * then, request to fetch data for the static-page.
   * @method ngOnInit 
   */
  ngOnInit() {
    this.routerNavFlag = false;
    this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.getStaticPageData();
      }
    });
    /**
     * for first time page load
     */
    if (!this.routerNavFlag) {
      this.getStaticPageData();
    }
  }

  /**
   * unsubscribe all subscriptions
   * @method ngOnDestroy
   */
  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }

  /**
   * fetches static-page data from cdn-url
   * First, all static-links are fetched, then find the link based upon current-url,
   * then, use that link to fetch data for that page.
   * If, no link is found, then show error-page.
   * @method getStaticPageData
   */
  getStaticPageData() {
    this.homePageService.getQuickLinks((resp) => {
      if (resp.data) {
        this.quickLinks = resp.data;
        this.quickLinks.forEach(item => {
          if (this.location.path() === ("/" + item.pageURL)) {
            this.cdnPath = item.linkPath;
            this.heading = item.pageTitle;
            this.errorFlag = false;
            if (this.cdnPath != undefined && this.cdnPath != "") {
              this.httpService.getCDNContent(this.cdnPath, (data) => {
                if (data != undefined && data != "")
                  this.content = data;
              });
            }
          }
        })
        if (this.errorFlag) {
          this.img404Src = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400;
        }
      }
    });
  }

  /**
   * whenever image error event triggers, a default image is set, and make onerror event null, so that it doesn't trigger again.
   * @method imgErrorHandler
   * @param event : image error event
   */
  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
