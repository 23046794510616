<div class="searchInnerContainer" (click)="clicked($event)">
  <div class="search-panel">
    <ls-suggestion #suggestionEle max-list-height="500px" list-height="auto" [tags]="false" (optionSelected)="navigateToDetailPage($event, suggestionEle)"
    searchon-param-name="siteId" [searchon-param-value]="parameters" current-param-name="keyword"
      base-url={{baseUrl}} placeholder="Search for Products..." [container]="optionComponent" (enterKeyPressed)="closeSearch()" [request-type]="2" ></ls-suggestion>
    <div id="goPnl" class="goPnl" fxLayoutAlign="center center">
      <span id="lnkGo" class="fa fa-search" (click)="changeUrlSearch(suggestionEle.textValue, suggestionEle)"></span>
    </div>
  </div>
  <div class="closeBtn" (click)="closeSearch()">
    <span>X</span>
  </div>
</div>