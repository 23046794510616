import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackOrderService } from '../../../services/track-order.service';
import { OrderModel } from '../../../common/models/orderModel';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { QueryComponent } from '../../../published/commonComponent/query/query.component';
import { TemplateConstants } from '../../commonComponent/template-constants';

/**
 * components which shows details of order, when user tracks order.
 * @class OrderTrackingComponent
 */
@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent implements OnInit {
  /**
   * @var order: contains order details
   */
  order: OrderModel
  /**
   * @var const: contains all constants
   */
  const = Constants
  /**
   * @var showLeft: flag for left tab display
   */
  showLeft: boolean;
  /**
   * @var showRight: flag for right tab display 
   */
  showRight: boolean;
  showDetails = [];
  /**
   * @var currType: stores
   */
  currType: string = "INR";
  /**
   * @var noOrdersFlag: flag for no orders
   */
  noOrdersFlag: boolean = false;
  /**
   * @var defaultPath: stores default image path for product's image
   */
  defaultPath = TemplateConstants.defaultImgPath;
  priceRoundOffInteger: string = '1.2';

  constructor(
    public activatedRoute: ActivatedRoute,
    private trackOrderService: TrackOrderService,
    private queryPopUpService: LsDialogService<QueryComponent>,

  ) { }

  /**
   * fetch order details
   * initialize order-detail view
   * @method ngOnInit
   */
  ngOnInit() {
    /**
     * handle price-round-off for cart
     */
    if (Constants.ONLINE_STORE_SETTING_MODEL != undefined) {
      if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined) {
        if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1) {
          this.priceRoundOffInteger = '1.0';
        } else if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0) {
          this.priceRoundOffInteger = '1.2';
        }
      }
    }
    this.getOrderDetails();
    this.showLeft = true
  }

  /**
   * @method toggleShowDetails: deprecated 
   */
  toggleShowDetails(index) {
    this.showDetails[index] = !this.showDetails[index];
  }

  /**
   * fetches order details by using emailId and orderId from current url's query params,
   * populate order details.
   * @method getOrderDetails
   */
  getOrderDetails() {
    let email = this.activatedRoute.snapshot.queryParamMap.get("email").toString();
    let orderId = this.activatedRoute.snapshot.queryParamMap.get('orderId').toString();
    this.trackOrderService.trackOrderUsingOrderId(email, orderId, (data) => {
      if (data != undefined && !data.error && data.data != undefined) {
        this.order = data.data[0];
      } else if (data != undefined && data.error) {
        this.noOrdersFlag = true;
      }
    });
  }

  /**
   * shows left tab
   * @method leftClick
   */
  leftClick() {
    this.showLeft = true
    this.showRight = false;
  }

  /**
   * shows right tab
   * @method rightClick
   */
  rightClick() {
    this.showRight = true;
    this.showLeft = false;
  }

  /**
  * whenever image error event triggers, a default image is set, and make onerror event null, so that it doesn't trigger again.
  * @method imgErrorHandler
  * @param event : image error event
  */
  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
